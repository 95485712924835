<template>
  <!-- 获取id 需要修改 -->
  <div
    class="tc-report"
    v-loading.fullscreen.lock="fullscreenLoading"
    element-loading-text="拼命加载中"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <!-- 页面功能 -->
    <div class="tool">
      <ul>
        <li>
          <img src="../../assets/images/refresh.png" @click="refresh()" alt />
        </li>
        <li>
          <img src="../../assets/images/retreatBtn.png" @click="back()" alt />
        </li>
      </ul>
    </div>
    <!-- 头部工具 -->
    <div
      class="tc-report-header"
      v-if="$route.path.split('/')[1] != 'answer_content'"
    >
      <!-- <router-link target="_blank" :to="{ path: '/copydownpdf', query: uid }"> -->
        <el-button type="primary" plain @click="handlePrint">打印报告</el-button>
      <!-- </router-link> -->
      <el-divider direction="vertical"></el-divider>
      <el-button type="primary" plain @click="showSetReportBox"
        >打印设置</el-button
      >
      <el-divider direction="vertical"></el-divider>
      <el-button type="primary" plain @click="infoEditState" v-if="!editState"
        >编辑内容</el-button
      >
      <el-button type="primary" plain @click="seveEditInfo" v-if="editState"
        >保存</el-button
      >
      <el-divider direction="vertical"></el-divider>
      <el-button type="primary" plain @click="downWord">导出Word</el-button>
			<el-button type="primary" plain @click="audit" v-if="inspect !== 1">审核</el-button>
    </div>
    <!-- 报告设置模态框 -->
    <el-dialog
      title="打印设置"
      :visible.sync="SetReportBox"
      width="30%"
      center
      @close="resc"
    >
      <el-form ref="form" :model="form" label-width="120px" size="mini">
        <el-form-item label="基本信息：">
          <el-radio-group v-model="form.scope_basic">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="图形：">
          <el-radio-group v-model="form.scope_graph">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="因子表：">
          <el-radio-group v-model="form.scope_factor">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="分数级别：">
          <el-radio-group v-model="form.scope_score">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="因子说明：">
          <el-radio-group v-model="form.scope_explain">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="指导建议：">
          <el-radio-group v-model="form.scope_suggest">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="测试师：">
          <el-radio-group v-model="form.scope_assessor">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="用户答案：">
          <el-radio-group v-model="form.user_scope_question">
            <el-radio label="1">显示</el-radio>
            <el-radio label="0">隐藏</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="SetReportBox = false">取 消</el-button>
        <el-button type="primary" @click="onSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 内容主题 -->
    <div v-show="true" id="ccc">
      <div
        class="tc-report-main"
        v-for="(item, key) in tableInfoList"
        :key="key"
      >
        <!-- 普通报告 -->
        <div
          class="content"
          id="content_a"
          :style="{ fontSize }"
          v-if="!item.results"
        >
          <!-- 标题 -->
          <div class="report-title">
            <p>耳鸣综合测评报告</p>
          </div>
          <!-- 用户信息 -->
          <div class="userInfo" v-show="form.scope_basic == 1">
            <table>
              <tr>
                <td>
                  姓名：
                  <span>{{ item.customer.name }}</span>
                </td>
                <td>
                  性别：
                  <span>{{ item.customer.sex }}</span>
                </td>
                <td>
                  出生日期：
                  <span>{{ item.customer.birthday }}</span>
                </td>
              </tr>
              <tr>
                <td>
                  编号：
                  <span>{{ item.customer.account }}</span>
                </td>
                <td>
                  科室：
                  <span>{{ item.customer.department }}</span>
                </td>
                <td>
                  报告日期：
                  <span>{{ item.customer.evaluating_time }}</span>
                </td>
              </tr>
              <template v-if="item.customer.archives">
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span>{{ item2.value }}</span>
                  </td>
                </tr>
              </template>
            </table>
          </div>
          <div class="table" v-show="form.scope_factor == 1">
            <div class="table-main">
              <el-row v-if="item.factor_results.length <= 3">
                <!-- 因子数小于三 -->
                <el-col>
                  <table class="one">
                    <tr>
                      <th>检测项目</th>
                      <th>程度</th>
                      <th>得分</th>
                    </tr>
                    <!-- -------------检测项目---------------- -->
                    <tr
                      v-for="(item2, key2) in item.factor_results"
                      :key="key2"
                    >
                      <td>{{ item2.name }}</td>
                      <td>{{ item2.mark }}</td>
                      <td>{{ item2.score }}</td>
                    </tr>
                  </table>
                </el-col>
              </el-row>
              <el-row v-if="item.factor_results.length > 3">
                <table class="results-two" cellspacing="0">
                  <thead>
                    <tr>
                      <th>检测项目</th>
                      <th>程度</th>
                      <th>得分</th>
                      <th>检测项目</th>
                      <th>程度</th>
                      <th>得分</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item2, key2) in item.factor_results">
											<tr :key="key2">
											  <template v-if="item.factor_results[key2 * 2]">
											    <td>{{ item.factor_results[key2 * 2].name }}</td>
											    <td>{{ item.factor_results[key2 * 2].mark }}</td>
											    <td>{{ item.factor_results[key2 * 2].score }}</td>
											  </template>
											  <template v-if="item.factor_results[key2 * 2 + 1]">
											    <td>{{ item.factor_results[key2 * 2 + 1].name }}</td>
											    <td>{{ item.factor_results[key2 * 2 + 1].mark }}</td>
											    <td>{{ item.factor_results[key2 * 2 + 1].score }}</td>
											  </template>
											</tr>
										</template>
                  </tbody>
                </table>
              </el-row>
            </div>
          </div>
          <!-- 测试结果title -->
          <p
            class="test-msg"
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            报告分析:
          </p>
          <!-- 每个项目分析 -->
          <div
            class="analyze"
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            <!-- v-show  隐藏没内容的 因子 -->
            <template v-for="(item2, key2) in item.factor_results"
							    v-show="item2.comment != '' || item2.advice != ''">
							<table :key="key2" >
							    <!--  v-show="item2.comment" -->
							    <tr class="analyze-title" v-show="form.scope_score == 1 && item2.name">
							      <span v-if="!editState">【{{ item2.name }}】</span>
							      <span v-if="editState">
							        检测项目
							        <el-input
							          v-model="item2.name"
							          placeholder="请输入内容"
							        ></el-input>
							      </span>
							      分数级别：
							      <span v-if="!editState">【{{ item2.mark }} 】</span>
							      <el-input
							        v-model="item2.mark"
							        placeholder="请输入内容"
							        v-if="editState"
							      ></el-input
							      >得分：
							      <span v-if="!editState">{{ item2.score }}</span>
							      <el-input
							        v-model="item2.score"
							        placeholder="请输入内容"
							        v-if="editState"
							      ></el-input>
							      <!-- v-if="item2.comment" -->
							    </tr>
							    <tr v-if="item2.comment" v-show="form.scope_explain == 1">
							      <td class="analyze-title">【说 明】</td>
							    </tr>
							    <tr v-if="item2.comment" v-show="form.scope_explain == 1">
							      <td v-html="item2.comment" v-if="!editState"></td>
							      <td>
							        <el-input
							          type="textarea"
							          :rows="6"
							          placeholder="请输入内容"
							          v-model="item2.comment"
							          v-if="editState"
							        ></el-input>
							      </td>
							    </tr>
							    <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
							      <td class="analyze-title">【指导建议】</td>
							    </tr>
							    <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
							      <td v-html="item2.advice" v-if="!editState"></td>
							      <td>
							        <el-input
							          type="textarea"
							          :rows="8"
							          placeholder="请输入内容"
							          v-model="item2.advice"
							          v-if="editState"
							        ></el-input>
							      </td>
							    </tr>
							  </table>
							
						</template>
						</div>
          <!-- 配合程度 -->
          <div class="degree" style="margin: 10px 0" v-if="isDegree">
            <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
            <el-select v-model="uid.degree" placeholder="请选择配合程度">
              <el-option label="低" value="低"></el-option>
              <el-option label="中" value="中"></el-option>
              <el-option label="高" value="高"></el-option>
            </el-select>
          </div>
          <!-- 用户答题 -->
          <div
            class="user-answers"
            v-if="item.question && form.user_scope_question == 1"
          >
            <p class="test-msg">用户答案:</p>
            <div
              class="user-answers-item"
              v-for="(item2, key) in item.question"
              :key="key"
            >
              <div class="title" v-html="item2.question"></div>
              <span class="answers" v-html="'【答案】:' + item2.answer"></span>
            </div>
          </div>
          <!-- 医生签名 -->
          <div class="signature">
            <span class="title">
              <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                >测评师:_______________</span
              >
              报告医生 ：
              <span class="signature_img">
                <img :src="item.admin.sign_img" alt width="150" />
              </span>
            </span>
            <span class="title data">报告日期 ：</span>
            <span>{{ item.customer.evaluating_time }}</span>
          </div>
        </div>
       </div>
    </div>
    <!-----------------导出word-------------------->
    <div id="downWord" v-show="false">
      <!-- 内容主题 -->
      <div
        v-for="(item, key) in tableInfoList"
        :key="key"
        :id="'downWord' + key"
      >
        <!-- 医院名称 -->
        <div class="units_name" style="margin-bottom: 30px">
          <p style="font-size: 33px; font-weight: 700; text-align: center">
            {{ config.unit_name }}
          </p>
        </div>
        <!-- 普通报告 -->
        <div
          class="content"
          id="content_a"
          style="
            width: 900px;
            margin: auto;
            background: white;
            padding: 20px 60px;
            box-sizing: border-box;
          "
          v-if="!item.results"
        >
          <!-- 标题 -->
          <div class="report-title" style="font-size: 30px; text-align: center">
            <p>{{ item.measure_title }}</p>
          </div>
          <!-- 用户信息 -->
          <div
            class="userInfo"
            v-show="form.scope_basic == 1"
            style="margin-top: 20px"
          >
            <table
              style="
                margin: auto;
                width: 100%;
                border-top: 1px black dashed;
                border-left: 1px black dashed;
              "
            >
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  姓名：
                  <span style="font-weight: 300">{{ item.customer.name }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  性别：
                  <span style="font-weight: 300">{{ item.customer.sex }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  出生日期：
                  <span style="font-weight: 300">{{
                    item.customer.birthday
                  }}</span>
                </td>
              </tr>
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  编号：
                  <span style="font-weight: 300">{{
                    item.customer.account
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  科室：
                  <span style="font-weight: 300">{{
                    item.customer.department
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  报告日期：
                  <span style="font-weight: 300">{{
                    item.customer.evaluating_time
                  }}</span>
                </td>
              </tr>
              <template v-if="item.customer.archives">
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
              </template>
            </table>
          </div>
          <!-- 测试结果title -->
          <p
            style="
              padding-left: 0px;
              margin-top: 10px;
              font-size: 25px;
              color: orange;
              text-shadow: black 2px 1px 1px;
              font-weight: 700;
            "
            v-show="form.scope_graph == 1"
          >
            测试结果:
          </p>
          <!-- 测试-图 -->
          <div
            style="margin-top: 10px; height: 250px"
            v-show="form.scope_graph == 1"
          >
            <!-- 90,圆形图 -->
            <!-- <div :id="'chart-report'+key" :style="{width: '100%', height: '100%',margin: '0 auto'}"></div> -->
            <div style="margin: '0 auto '; text-align: center; width: '100%'">
              <img :src="wordImgUrl[key]" alt />
            </div>
          </div>
          <!-- 测试-表 -->
          <div
            class="table"
            style="
              overflow: hidden;
              margin-top: 15px;
              box-sizing: border-box;
              padding: 20px 0;
              border-top: 1px black solid;
              border-bottom: 1px black solid;
            "
            v-show="form.scope_factor == 1"
          >
            <div class="table-main">
              <el-row v-if="item.factor_results.length <= 3">
                <!-- 因子数小于三 -->
                <el-col>
                  <table
                    class="one"
                    style="
                      width: 100%;
                      border-top: 1px black dashed;
                      border-left: 1px black dashed;
                    "
                  >
                    <tr style="width: 100%">
                      <th
                        style="
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                        "
                      >
                        检测项目
                      </th>
                      <th
                        style="
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                        "
                      >
                        程度
                      </th>
                      <th
                        style="
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                        "
                      >
                        得分
                      </th>
                    </tr>
                    <!-- -------------检测项目---------------- -->
                    <tr
                      style="width: 100%"
                      v-for="(item2, key2) in item.factor_results"
                      :key="key2"
                    >
                      <td
                        style="
                          text-align: center;
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                          font-size: 14px;
                        "
                      >
                        {{ item2.name }}
                      </td>
                      <td
                        style="
                          text-align: center;
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                          font-size: 14px;
                        "
                      >
                        {{ item2.mark }}
                      </td>
                      <td
                        style="
                          text-align: center;
                          border-right: 1px black dashed;
                          border-bottom: 1px black dashed;
                          padding: 5px 0;
                          font-size: 14px;
                        "
                      >
                        {{ item2.score }}
                      </td>
                    </tr>
                  </table>
                </el-col>
              </el-row>
              <el-row v-if="item.factor_results.length > 3">
                <table
                  class="results-two"
                  cellspacing="0"
                  style="
                    width: 100%;
                    border-top: 1px dashed black;
                    border-left: 1px dashed black;
                  "
                >
                  <thead>
                    <tr>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 160px;
                        "
                      >
                        检测项目
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 180px;
                        "
                      >
                        程度
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 50px;
                        "
                      >
                        得分
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 160px;
                        "
                      >
                        检测项目
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 180px;
                        "
                      >
                        程度
                      </th>
                      <th
                        style="
                          padding: 5px 0;
                          border-bottom: 1px dashed black;
                          border-right: 1px dashed black;
                          width: 50px;
                        "
                      >
                        得分
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item2, key2) in item.factor_results"
                      :key="key2"
                    >
                      <template v-if="item.factor_results[key2 * 2]">
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 160px;
                          "
                        >
                          {{ item.factor_results[key2 * 2].name }}
                        </td>
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 180px;
                          "
                        >
                          {{ item.factor_results[key2 * 2].mark }}
                        </td>
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 50px;
                          "
                        >
                          {{ item.factor_results[key2 * 2].score }}
                        </td>
                      </template>
                      <template v-if="item.factor_results[key2 * 2 + 1]">
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 160px;
                          "
                        >
                          {{ item.factor_results[key2 * 2 + 1].name }}
                        </td>
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 180px;
                          "
                        >
                          {{ item.factor_results[key2 * 2 + 1].mark }}
                        </td>
                        <td
                          style="
                            box-sizing: border-box;
                            padding: 2px 5px 2px 5px;
                            vertical-align: middle;
                            border-bottom: 1px dashed black;
                            border-right: 1px dashed black;
                            text-align: center;
                            width: 50px;
                          "
                        >
                          {{ item.factor_results[key2 * 2 + 1].score }}
                        </td>
                      </template>
                    </tr>
                  </tbody>
                </table>
              </el-row>
            </div>
          </div>
          <!-- 测试结果title -->
          <p
            style="
              padding-left: 0px;
              margin-top: 10px;
              font-size: 25px;
              color: orange;
              text-shadow: black 2px 1px 1px;
              font-weight: 700;
            "
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            报告分析:
          </p>
          <!-- 健康状态 -->
          <!-- 每个项目分析 -->
          <div
            class="analyze"
            style="margin-top: 20px; padding-bottom: 10px"
            v-show="
              form.scope_explain != 0 ||
              form.scope_explain != 0 ||
              form.scope_suggest != 0
            "
          >
            <!-- v-show  隐藏没内容的 因子 -->
            <table
              style="
                padding: 10px 0;
                border-bottom: 1px black solid;
                width: 100%;
              "
              v-for="(item2, key2) in item.factor_results"
              :key="key2"
              v-show="item2.comment != '' || item2.advice != ''"
            >
              <!--  v-show="item2.comment" -->
              <tr
                style="
                  text-indent: 0;
                  padding-top: 10px;

                  font-weight: 700;
                "
                class="analyze-title"
                v-show="form.scope_score == 1"
              >
                【{{
                  item2.name
                }}】 分数级别：{{
                  item2.mark
                }}
                <span>得分：{{ item2.score }}</span>
                <!-- v-if="item2.comment" -->
              </tr>
              <tr v-if="item2.comment" v-show="form.scope_explain == 1">
                <td
                  style="
                    text-indent: 0;
                    padding-top: 10px;

                    font-weight: 700;
                  "
                  class="analyze-title"
                >
                  【说 明】
                </td>
              </tr>
              <tr v-if="item2.comment" v-show="form.scope_explain == 1">
                <td
                  style="
                    text-indent: 2em;
                    letter-spacing: 3px;
                    line-height: 25px;
                  "
                  v-html="item2.comment"
                ></td>
              </tr>
              <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
                <td
                  style="
                    text-indent: 0;
                    padding-top: 10px;
                    // font-size: 16px;
                    font-weight: 700;
                  "
                  class="analyze-title"
                >
                  【指导建议】
                </td>
              </tr>
              <tr v-if="item2.advice" v-show="form.scope_suggest == 1">
                <td
                  style="
                    text-indent: 2em;
                    letter-spacing: 3px;
                    line-height: 25px;
                  "
                  v-html="item2.advice"
                ></td>
              </tr>
            </table>
          </div>
          <!-- 配合程度 -->
          <div
            class="degree"
            style="margin: 10px 0"
            v-if="isDegree && uid.degree"
          >
            <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
            <p style="text-indent: 2em">{{ uid.degree }}</p>
          </div>
          <!-- 用户答题 -->
          <div class="user-answers" v-if="item.question">
            <p
              class="test-msg"
              style="
                padding-left: 0px;
                margin-top: 10px;
                font-size: 25px;
                color: orange;
                text-shadow: black 2px 1px 1px;
                font-weight: 700;
              "
            >
              用户答案:
            </p>
            <div
              class="user-answers-item"
              v-for="(item2, key) in item.question"
              :key="key"
              style="margin-bottom: 10px; color: black"
            >
              <div class="title" v-html="item2.question"></div>
              <span
                class="answers"
                style="font-weight: 700; display: inline-block"
                v-html="'【答案】:' + item2.answer"
              ></span>
            </div>
          </div>
          <!-- 医生签名 -->
          <div
            class="signature"
            style="position: relative; text-align: right; margin-top: 40px"
          >
            <span class="title" style="font-weight: 700">
              <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                >测评师:_______________</span
              >
              报告医生 ：
              <span
                class="signature_img"
                style="display: inline-block; min-width: 100px"
              >
                <img :src="signatureImg[key]" alt width="80" height="50" />
              </span>
            </span>
            <span class="title data" style="font-weight: 700; margin-left: 20px"
              >报告日期 ：</span
            >
            <span>{{ item.customer.evaluating_time }}</span>
          </div>
        </div>
        <!-- 明尼苏达多相个性测查表 -->
        <div
          v-if="item.results"
          class="content minnesota"
          style="
            box-sizing: border-box;
            width: 900px;
            margin: auto;
            background: white;
          "
        >
          <!-- 标题  -->
          <div class="report-title" style="font-size: 30px; text-align: center">
            <p>{{ item.measure_title }}</p>
          </div>
          <!-- 用户信息 -->
          <div
            class="userInfo"
            v-show="form.scope_basic == 1"
            style="margin-top: 20px"
          >
            <table
              style="
                margin: 0 auto;
                width: 100%;
                border-top: 1px black dashed;
                border-left: 1px black dashed;
              "
            >
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  姓名：
                  <span style="font-weight: 300">{{ item.customer.name }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  性别：
                  <span style="font-weight: 300">{{ item.customer.sex }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  出生日期：
                  <span style="font-weight: 300">{{
                    item.customer.birthday
                  }}</span>
                </td>
              </tr>
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  编号：
                  <span style="font-weight: 300">{{
                    item.customer.account
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  科室：
                  <span style="font-weight: 300">{{
                    item.customer.department
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  报告日期：
                  <span style="font-weight: 300">{{
                    item.customer.evaluating_time
                  }}</span>
                </td>
              </tr>
              <template v-if="item.customer.archives">
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(0, 3)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(3, 6)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(6, 9)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
                <tr style="width: 100%; font-weight: 700">
                  <td
                    style="
                      padding: 5px;
                      border-bottom: 1px black dashed;
                      border-right: 1px black dashed;
                    "
                    v-for="(item2, key) in item.customer.archives.slice(9, 12)"
                    :key="key"
                  >
                    {{ item2.name }} :
                    <span style="font-weight: 300">{{ item2.value }}</span>
                  </td>
                </tr>
              </template>
              <tr style="width: 100%; font-weight: 700">
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  项目数:
                  <span style="font-weight: 300">{{
                    item.results.mmpi.statistics.dashi +
                    item.results.mmpi.statistics.dafou
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  答是数量:
                  <span style="font-weight: 300">{{
                    item.results.mmpi.statistics.dashi
                  }}</span>
                </td>
                <td
                  style="
                    padding: 5px;
                    border-bottom: 1px black dashed;
                    border-right: 1px black dashed;
                  "
                >
                  答否数量:
                  <span style="font-weight: 300">{{
                    item.results.mmpi.statistics.dafou
                  }}</span>
                </td>
              </tr>
            </table>
          </div>
          <br />
          <!-- MMPI 基 本 量 表 分 -->
          <div class="mmpi" style="margin-top: 40px">
            <div
              class="title"
              style="text-align: center; font-size: 18px; font-weight: 700"
            >
              MMPI 基 本 量 表 分
            </div>
            <hr style="margin: 5px 0" />
            <div class="mmpi_table" style="margin-top: 20px">
              <!-- 共2行 -->
              <table style="width: 100%; font-size: 14px">
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        height: 120px;
                        writing-mode: vertical-lr;
                        letter-spacing: 8px;
                      "
                    ></span>
                  </td>
                  <td
                    style="
                           width:5.71%
                           text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.help"
                    :key="index"
                  >
                    <span
                      style="
                        display: inline-block;
                        height: 120px;
                        writing-mode: vertical-lr;
                        letter-spacing: 8px;
                      "
                      >{{ key }}</span
                    >
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >分数：</span
                    >
                  </td>
                  <td
                    style="
                            width:5.71%
                            text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.help"
                    :key="index"
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >{{ res }}</span
                    >
                  </td>
                </tr>
              </table>
              <hr style="margin: 5px 0" />
              <!-- 共4行 -->
              <table style="width: 100%; font-size: 14px">
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >原始分：</span
                    >
                  </td>
                  <td
                    style="
                           width:5.71%
                           text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.oldCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >K 校 正 分：</span
                    >
                  </td>
                  <td
                    style="
                            width:5.71%
                            text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.kCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >中国常模不做K校正的T分：</span
                    >
                  </td>
                  <td
                    style="
                           width:5.71%
                           text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.kNoCmCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="width:20%
                             text-align: left;
                            "
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >中国常模做K校正的T分：</span
                    >
                  </td>
                  <td
                    style="
                           width:5.71%
                           text-align:center
                            ;"
                    v-for="(res, key, index) in item.results.mmpi.kCmCore"
                    :key="index"
                  >
                    <span>{{ res }}</span>
                  </td>
                </tr>
              </table>
              <hr style="margin: 5px 0" />
              <div class="mmpi_summarize" style="padding: 5px 0">
                <span style="padding: 0 20px; border-right: 1px black solid"
                  >两点峰型:{{ item.results.mmpi.statistics.twofx }}</span
                >
                <span style="padding: 0 20px; border-right: 1px black solid"
                  >矛盾题:{{ item.results.mmpi.statistics.maodun }}</span
                >
                <span style="padding: 0 20px; border-right: 1px black solid"
                  >答"是"的比例:{{
                    item.results.mmpi.statistics.pro_yes
                  }}
                  %</span
                >
                <span style="padding: 0 20px; border-right: 1px black solid"
                  >答"否"的比例:{{
                    item.results.mmpi.statistics.pro_no
                  }}
                  %</span
                >
              </div>
              <hr style="margin: 5px 0" />
            </div>
          </div>
          <br />
          <!-- T 分 顺 序 表 -->
          <div class="t" style="margin-top: 40px">
            <div
              class="title"
              style="text-align: center; font-size: 18px; font-weight: 700"
            >
              T 分 顺 序 表
            </div>
            <hr style="margin: 5px 0" />
            <div class="t_table" style="margin-top: 20px">
              <!-- 分 -->
              <table style="width: 100%; font-size: 14px">
                <tr style="display: flex">
                  <td
                    style="flex: 1; display: flex; justify-content: center"
                    v-for="(res, key, index) in item.results.tcore.core"
                    :key="index"
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >{{ key }}</span
                    >
                  </td>
                </tr>
                <tr style="display: flex">
                  <td
                    style="flex: 1; display: flex; justify-content: center"
                    v-for="(res, key, index) in item.results.tcore.core"
                    :key="index"
                  >
                    <span
                      style="
                        display: inline-block;
                        text-align: center;
                        padding: 5px 0;
                      "
                      >{{ res }}</span
                    >
                  </td>
                </tr>
              </table>
              <!-- 表格 -->
              <table
                style="
                  width: 100%;
                  font-size: 14px;
                  border: black 1px solid;
                  border-collapse: collapse;
                "
              >
                <tr>
                  <td
                    style="
                      text-align: center;
                      border-right: black 1px solid;
                      border-bottom: black 1px solid;
                    "
                  >
                    因子量表
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: black 1px solid;
                      border-bottom: black 1px solid;
                    "
                  >
                    T 分
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: black 1px solid;
                      border-bottom: black 1px solid;
                    "
                  >
                    低分者特征
                  </td>
                  <td
                    style="
                      text-align: center;
                      border-right: black 1px solid;
                      border-bottom: black 1px solid;
                    "
                  >
                    高分者特征
                  </td>
                </tr>
                <tr v-for="(res, key2) in item.results.tcore.tdes" :key="key2">
                  <!-- 因 返回的数据 0-3为 一条td，故 %3 处理 不显示  -->
                  <template v-if="key2 % 3 == 0">
                    <!-- 因子量表 -->
                    <td
                      style="
                        text-align: center;
                        border-right: black 1px solid;
                        border-bottom: black 1px solid;
                      "
                    >
                      {{ res.tagdes }}
                      <br />
                      <span
                        v-html="item.results.tcore.tdes[key2 + 1].tagdes"
                      ></span>
                    </td>
                    <!-- 分数 -->
                    <td
                      style="
                        text-align: center;
                        border-right: black 1px solid;
                        border-bottom: black 1px solid;
                      "
                    >
                      {{ res.score }}
                    </td>
                    <!-- 低分者特征 -->
                    <td
                      style="
                        text-align: center;
                        border-right: black 1px solid;
                        border-bottom: black 1px solid;
                      "
                    >
                      {{ res.ddesc }}
                      <br />
                      {{ item.results.tcore.tdes[key2 + 1].ddesc }}
                    </td>
                    <!-- 高 分者特征 -->
                    <td
                      style="
                        text-align: center;
                        border-right: black 1px solid;
                        border-bottom: black 1px solid;
                      "
                    >
                      {{ res.gdesc }}
                      <br />
                      {{ item.results.tcore.tdes[key2 + 1].gdesc }}
                    </td>
                  </template>
                </tr>
              </table>
              <br />
              <!-- 图表 -->
              <div class="chart" v-show="form.scope_graph == 1">
                <!-- 90,圆形图 -->
                <!-- <div :id="'chart-report'+key" :style="{width: '100%', height: '100%'}"></div> -->
                <div
                  style="margin: '0 auto '; text-align: center; width: '100%'"
                >
                  <img :src="wordImgUrl[key]" alt />
                </div>
              </div>
              <!-- 预测内容 -->
              <div
                class="forecast"
                v-if="item.results.war"
                style="margin-top: 30px"
              >
                <table
                  style="
                    width: 100%;
                    border-collapse: separate;
                    border-spacing: 5px 5px;
                  "
                >
                  <caption
                    style="font-size: 16px; font-weight: 700; padding: 10px 0"
                  >
                    危险行为预测
                  </caption>
                  <tr>
                    <td style="text-align: center; font-weight: 700">
                      预测内容
                    </td>
                    <td style="width: 50px; text-align: right">1</td>
                    <td style="width: 50px; text-align: right">2</td>
                    <td style="width: 50px; text-align: right">3</td>
                    <td style="width: 50px; text-align: right">4</td>
                    <td style="width: 50px; text-align: right">5</td>
                    <td style="width: 50px; text-align: right">6</td>
                    <td style="width: 50px; text-align: right">7</td>
                    <td style="width: 50px; text-align: right">8</td>
                    <td style="width: 50px; text-align: right">9</td>
                    <td style="width: 50px; text-align: right">10</td>
                  </tr>
                  <tr
                    v-for="(res, k, index) in item.results.war"
                    :key="index"
                    style
                  >
                    <td style="text-align: left; width: 120px">{{ k }}:</td>
                    <td
                      v-for="index2 in res"
                      :key="index2"
                      style="
                        background: rgb(63, 62, 62);
                        width: 50px;
                        text-align: right;
                      "
                    ></td>
                  </tr>
                  <!-- 程度 -->
                  <tr style="transform: translateX(4px); font-weight: 700">
                    <td></td>
                    <td>小</td>
                    <td></td>
                    <td></td>
                    <td>中</td>
                    <td></td>
                    <td>大</td>
                    <td></td>
                    <td>极大</td>
                    <td></td>
                    <td></td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <br />
          <!-- 临 床 亚 量 表 -->
          <div class="conclusion" style="margin-top: 40px">
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                临 床 亚 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k, index) in item.results.lc" :key="index">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 附加量表 -->
          <div
            class="conclusion"
            v-if="item.results.fjlb"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                附 加 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.fjlb" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 适应不良量表 -->
          <div
            class="conclusion"
            v-if="item.results.sybllb"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                适 应 不 良 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.sybllb" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 预测性量表 -->
          <div
            class="conclusion"
            v-if="item.results.ycxlb"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                预 测 性 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.ycxlb" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 性功能和性体验障碍量表 -->
          <div
            class="conclusion"
            v-if="item.results.xgn"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                性 功 能 和 性 体 验 障 碍 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.xgn" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div
              class="remind"
              style="
                border-top: 1px black solid;
                border-bottom: 1px black solid;
                padding: 5px 0;
              "
            >
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <!-- 内容量表 -->
          <div
            class="conclusion"
            v-if="item.results.nrlb"
            style="margin-top: 40px"
          >
            <table style="width: 100%; border-collapse: collapse">
              <caption
                style="padding: 5px 0; font-weight: 700; font-size: 18px"
              >
                内 容 量 表
              </caption>
              <tr>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 200px;
                    text-align: center;
                  "
                >
                  <span>量表</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                    width: 150px;
                    text-align: center;
                  "
                >
                  <span>T分</span>
                </th>
                <th
                  style="
                    border-top: 1px black solid;
                    border-bottom: 1px black solid;
                    padding: 5px 0;
                  "
                >
                  <span>量表结果的解释</span>
                </th>
              </tr>
              <tr v-for="(r, k2) in item.results.nrlb" :key="k2">
                <td style="font-size: 14px; padding: 5px 0">{{ r.name }}</td>
                <td style="font-size: 14px; padding: 5px 0; text-align: center">
                  <span>{{ r.score }}</span>
                </td>
                <td style="font-size: 14px; padding: 5px 0">{{ r.comment }}</td>
              </tr>
            </table>
            <div class="remind">
              <p>
                注：量表结果的解释为空表明该项为正常或大多数人的分数在此范围。
              </p>
            </div>
          </div>
          <br />
          <!--辅助诊断意见 -->
          <div
            class="auxiliary"
            v-if="item.results.xlzz"
            style="margin-top: 40px"
          >
            <div
              class="title"
              style="text-align: center; font-size: 18px; font-weight: 700"
            >
              * * *辅助诊断意见(仅供临床参考)* * *
            </div>
            <hr style="margin: 5px 0" />
            <div
              class="auxiliary_title"
              style="
                margin-top: 20px;
                font-weight: 700;
                font-size: 16px;
                text-align: center;
              "
            >
              * * *心理症状提示* * *
            </div>
            <hr style="margin: 5px 0" />
            <div class="auxiliary_main">
              <div
                class="auxiliary_item"
                v-for="(r2, k2) in item.results.xlzz"
                :key="k2"
                style="padding: 10px 0"
              >
                <div
                  class="item_title"
                  style="font-weight: 700; margin-bottom: 8px"
                >
                  {{ k2 + 1 }}、{{ r2.title }}({{ r2.fz }}/{{ r2.fm }})：
                </div>
                <div class="item_content" v-html="r2.desc"></div>
              </div>
            </div>
          </div>
          <br />
          <!-- 个性心理描述 -->
          <div class="describe" style="margin-top: 40px">
            <div class="title">* * * 个性心理描述 * * *</div>
            <hr style="margin: 5px 0" />
            <!-- 测谎 -->
            <div class="describe_main">
              <div
                class="describe_content_title"
                style="font-size: 16px; text-align: center"
              >
                <p
                  style="
                    margin: 30px 0px 9px 0px;
                    font-size: 16px;
                    font-weight: 700;
                  "
                >
                  测谎
                </p>
                <hr style="margin: 5px 0" />
              </div>
              <div
                class="describe_content"
                style="font-size: 14px; padding: 10px 0"
              >
                <p v-html="item.results.gxdesc.l"></p>
              </div>
            </div>
            <!-- 总效度分析 -->
            <div class="describe_main">
              <div
                class="describe_content_title"
                style="font-size: 16px; text-align: center"
              >
                <p
                  style="
                    margin: 30px 0px 9px 0px;
                    font-size: 16px;
                    font-weight: 700;
                  "
                >
                  总效度分析
                </p>
                <hr style="margin: 5px 0" />
              </div>
              <div
                class="describe_content"
                style="font-size: 14px; padding: 10px 0"
              >
                <p v-html="item.results.gxdesc.fx1"></p>
              </div>
            </div>
            <!-- 个性逐项分析 -->
            <div class="describe_main">
              <div
                class="describe_content_title"
                style="font-size: 16px; text-align: center"
              >
                <p
                  style="
                    margin: 30px 0px 9px 0px;
                    font-size: 16px;
                    font-weight: 700;
                  "
                >
                  个性逐项分析
                </p>
                <hr style="margin: 5px 0" />
              </div>
              <div
                class="describe_content"
                style="font-size: 14px; padding: 10px 0"
              >
                <p v-html="item.results.gxdesc.fx2"></p>
              </div>
            </div>
            <!-- 个性综合分析(本人常意识不到,应激,虚弱和疾病时明显) -->
            <div class="describe_main">
              <div
                class="describe_content_title"
                style="font-size: 16px; text-align: center"
              >
                <p
                  style="
                    margin: 30px 0px 9px 0px;
                    font-size: 16px;
                    font-weight: 700;
                  "
                >
                  个性综合分析(本人常意识不到,应激,虚弱和疾病时明显)
                </p>
                <hr style="margin: 5px 0" />
              </div>
              <div
                class="describe_content"
                style="font-size: 14px; padding: 10px 0"
              >
                <p v-html="item.results.gxdesc.fx3"></p>
              </div>
            </div>
          </div>
          <div
            class="remind"
            style="
              border-top: 1px black solid;
              border-bottom: 1px black solid;
              padding: 5px 0;
            "
          >
            <p style="text-align: center">
              ******（本报告仅供临床参考，不作诊断证明之用）******
            </p>
          </div>
          <!-- 总 体 描 述 -->
          <div
            class="zDescribe"
            v-if="item.results.ztyx"
            style="margin-top: 60px"
          >
            <div
              class="zDescribe_title"
              style="font-weight: 700; text-align: center"
            >
              总 体 描 述
            </div>
            <hr style="margin: 5px 0" />
            <div
              class="zDescribe_content"
              style="margin-top: 40px; font-size: 14px"
            >
              <p>{{ item.results.ztyx.desc }}</p>
            </div>
            <div class="zDescribe_msg" style="margin-top: 50px">
              <p style="font-weight: 700">
                检 查 结 果:
                <span style="font-weight: 100">{{
                  item.results.ztyx.con
                }}</span>
              </p>
            </div>
          </div>
          <!-- 配合程度 -->
          <div class="degree" style="margin: 10px 0" v-if="isDegree">
            <p style="font-weight: 700; margin-bottom: 10px">【配合程度】</p>
            <p style="text-indent: 2em">{{ uid.degree }}</p>
          </div>
          <!-- 医生建议 -->
          <br />
          <div
            class="suggest"
            style="
              border-top: 1px black solid;
              margin-bottom: 5px;
              padding: 5px 0;
            "
          >
            <p class="suggest_msg" style="font-weight: 700">医生建议：</p>
            <div class="suggest_content" style="height: 200px"></div>
            <!-- 医生签名 -->
            <div
              class="signature"
              style="position: relative; text-align: right; margin-top: 40px"
            >
              <span class="title" style="font-weight: 700">
                <span style="margin-right: 30px" v-if="form.scope_assessor == 1"
                  >测评师:_______________</span
                >
                报告医生 ：
                <span
                  class="signature_img"
                  style="display: inline-block; min-width: 100px"
                >
                  <img :src="signatureImg[key]" alt width="80" height="50" />
                </span>
              </span>
              <span
                class="title data"
                style="font-weight: 700; margin-left: 20px"
                >报告日期 ：</span
              >
              <span>{{ item.customer.evaluating_time }}</span>
            </div>
          </div>
          <div
            class="remind"
            style="
              border-top: 1px black solid;
              border-bottom: 1px black solid;
              padding: 5px 0;
            "
          >
            <p style="text-align: center">
              ******此报告仅供参考，请以医师诊断为准。******
            </p>
          </div>
        </div>
        <!-- 分页 -->
        <div class="pageNext"></div>
      </div>
    </div>
    <!-- 等待导出模态框 -->
    <el-dialog
      title="系统提示"
      :visible.sync="downLoad"
      :showClose="false"
      :closeOnClickModal="false"
      :closeOnCressEscape="false"
      width="600px"
    >
      <h3 v-if="downLoadNum < tableInfoList.length">
        正在生成第 {{ downLoadNum }} 份 - {{ downLoadTitle }}
      </h3>
      <template v-else>
        <h3>报告已全部生成，浏览器即将自动下载。请等待...</h3>
        <i class="downWordMsg" @click="twoDownWord()"
          >如果未自动下载,请点击我</i
        >
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="downLoad = false"
            >关 闭</el-button
          ></span
        >
      </template>
    </el-dialog>
  </div>
</template> 
<script>
import $ from "jquery";
import { loadReport, save_meaconclusion } from "@/api/report.js";
import { max } from "moment";
import { updateConfig } from "@/api/comm.js";
import saveAs from "file-saver";
import jszip from "jszip";
import moment from "moment";
import "../../../public/static/jquery.wordexport";
import { log } from "util";
import { inspectUp } from "@/api/information.js";
// import "./index.less"
export default {
  inject: ["reload"],
  data() {
    return {
      isDegree: false,
      degree: "", //配合程度
      config: {},
      downWords: false,
      aaaa: "page-break-before:always",
      tmp: {},
      form: {},
      SetReportBox: false,
      setReportForm: [],
      fullscreenLoading: true,
      userReporData: {
        customer: {
          id: 1,
          account: "iEfoam",
          name: "iEfoam",
          sex: "男",
          birthday: "1997-05-29",
          department_id: 1,
          unit_name: "重庆医科大学附属第二医院",
          department_name: "技术部门",
          age: 22,
        },
        measure_title: "心理健康临床症状自评量表(SCL-90)",
        theme: 3,
        base_results: {},
        warning: 0,
        factor_results: [],
      },
      uid: {},
      charDataList: [],
      charDataList2: [],
      charNameList: [],
      // ----------新--------------------
      // 图表数据
      chartDataList: [],
      // 表数据
      tableInfoList: [],
      listData1: [], //数值
      listData2: [], //参考值
      listData3: [], //图示名
      fontSize: "16px", // 字体大小
      wordImgUrl: [], //图标base64
      signatureImg: [], //签名base64
      // 编辑状态
      editState: false,
      // 等待下载
      downLoad: false,
      downLoadNum: 0,
      downLoadTitle: "",
      //zip数据
      zipData: null,
			isPrint:true,
			isInspect: true
    };
  },
  beforeCreate() {},
  created() {
		this.getData();
  },
  mounted() {
    // this.charData();
    this.$nextTick(() => {
      // this.charData();
      // 字体大小设置
      let fontS =
        JSON.parse(localStorage.getItem("config")).report_font_size + "px";
      this.fontSize = fontS;
      // ----------------------
    });
  },
  methods: {
		getData() {
			// 获取全局配置项
			  this.config = JSON.parse(localStorage.getItem("config"));
			  //  获取用户id.判断单个还是多个id
			  let data = {
			    id: "",
			  };
			  if (this.$route.query.id) {
			    // console.log("单");
			    this.isDegree = false; // 配合程度
			    data.id = this.$route.query.id;
			    this.uid.id = this.$route.query.id;
			  } else if (this.$route.query.ids) {
			    // console.log("多");
			    data.id = this.$route.query.ids;
			    this.uid.ids = this.$route.query.ids;
			    if (this.$route.query.ids.split(",").length == 1) {
			      this.isDegree = false;
			    }
			  }
			  // 发起请求渲染报告
			  loadReport(data).then((res) => {
					// print_no_inspect
					this.isPrint = res.print_no_inspect == 1 ? true : false;
					this.isInspect = res.data.every(value => value.inspect);
					let array = [];
					res.data.forEach(value => {
						if( value.base_results?.name) {
							const { name = "", mark = "", score = "",comment = "" } = value.base_results;
							array.push({name, mark, score,comment});
						};
						array.push(...value.factor_results);
					});
					let obj = {...res.data[0]};
					obj.base_results = {};
					res.data = obj;
					res.data.factor_results = [...array];
					res.data['result'] = [...array];
			    this.tmp = JSON.parse(JSON.stringify(res.config)); //获取-全局配置信息
			    // 判断是管理员还是用户端
			    if (this.tmp.user_scope_basic) {
			      this.form.scope_assessor = res.config.user_scope_assessor;
			      this.form.scope_basic = res.config.user_scope_basic;
			      this.form.scope_explain = res.config.user_scope_explain;
			      this.form.scope_factor = res.config.user_scope_factor;
			      this.form.scope_graph = res.config.user_scope_graph;
			      this.form.scope_score = res.config.user_scope_score;
			      this.form.scope_suggest = res.config.user_scope_suggest;
			      this.form.user_scope_question = res.config.user_scope_question;
			    } else {
			      this.form = res.config; //打印设置
			    }
			    let tmp = 0;
			    let infoTmp = [];
					this.inspect = res.data.inspect;
			    // 判断单个还是多个量表
			    if (this.$route.query.ids) {
			      tmp = this.$route.query.ids.split(",").length;
			    }
			    if (this.$route.query.id || tmp < 2) {
			      this.tableInfoList = [res.data];
			      infoTmp = [res.data];
			    } else {
			      this.tableInfoList = res.data;
			      infoTmp = res.data;
			    }
			
			    // 1-图表数据处理
			    let resArr = [];
			    let imgBase64 = [];
			    this.tableInfoList.forEach((v, k) => {
			      // --------------------------- 签名图片处理--------------------
			      this.convertImgToBase64(v.admin.sign_img, function (base64Img) {
			        imgBase64.push(base64Img);
			      });
			
			      // -----------------------------------共用报告数据处理------------------------------------------------
			      let thisArr = [];
			      let list1 = [];
			      let list2 = [];
			      let list3 = [];
			      // -----.base_results 添加至 数组 .factor_results----
			      //          true --- false                 true
			      // if (!(v.base_results instanceof Array) && v.base_results != {}) {
			      //   this.tableInfoList[k].factor_results.unshift(v.base_results);
			      // } else if (v.factor_results.length == 0) {
			      //   this.tableInfoList[k].factor_results.unshift(v.base_results);
			      // }
			      resArr[k] = thisArr;
			      this.listData1[k] = list1;
			      this.listData2[k] = list2;
			      this.listData3[k] = list3;
			      // ----------------------- 兼容处理 --------------------------------
			      // ------------1- 解决 程度 不存在 的数据 但是要渲染图中显示因子--------------
			      for (let i = 0; i < this.tableInfoList[k].factor_results.length; i++) {
			        this.tableInfoList[k].factor_results = this.tableInfoList[
			          k
			        ].factor_results.filter((v) => {
			          return v.mark != "";
			        });
			      }
			    });
			    this.chartDataList = resArr;
			    this.signatureImg = imgBase64;
			    // 1- 解决 程度 不存在 的数据 但是要渲染图中显示因子
			    // infoTmp.forEach((v, k) => {});
			  });
			
		},
    // 重置数据
    resc() {
      this.form = JSON.parse(JSON.stringify(this.tmp));
    },
    // 保持打印设置
    onSubmit() {
      updateConfig(this.form).then((res) => {
        if (res.code == 400200) {
          this.SetReportBox = false;
          this.form = this.form;
          this.tmp = JSON.parse(JSON.stringify(this.form));
          this.$message({
            showClose: true,
            message: "设置成功！",
            type: "success",
          });
        }
      });
    },
    // 控制打印设置
    showSetReportBox() {
      this.SetReportBox = true;
    },
    // 刷新
    refresh() {
      this.reload();
    },
    // 返回
    back() {
      this.$router.go(-1); //返回上一层
    },
		downWord() {
      let isTmp = true; // 关闭弹出会触发 catch。    控制 是否到导出完整测评报告   默认允许导出
      let num = 0;
      if (this.uid.ids) {
        num = this.uid.ids.split(",").length;
      } else {
        num = this.uid.id.split(",").length;
      }
      $(".pageNext").html(
        '<br clear=all style="page-break-before:always" mce_style="page-break-before:always">'
      );
			let title = this.tableInfoList[0].measure_title;
			let name = this.tableInfoList[0].customer.name;
			$(`#downWord`).wordExport(`耳鸣综合测评报告`);
    },
    // 手动下载
    twoDownWord() {
      this.zipData.then((res) => {
        res.generateAsync({ type: "blob" }).then(function (content) {
          var now = moment().format("YYYY-MM-DD HH:mm:ss");
          saveAs(content, `批量导出Word-${now}.zip`);
        });
      });
    },
    // 转Base64
    convertImgToBase64(url, callback, outputFormat) {
      var canvas = document.createElement("CANVAS"),
        ctx = canvas.getContext("2d"),
        img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = function () {
        canvas.height = img.height;
        canvas.width = img.width;
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL(outputFormat || "image/png");
        callback.call(this, dataURL);
        canvas = null;
      };
      img.src = url;
    },
    // 编辑内容
    infoEditState() {
      this.editState = true;
    },
    // 保存修改的内容
    seveEditInfo() {
      this.editState = false;
      // console.log(this.tableInfoList[0]);
      // 数据处理
      let data = JSON.parse(JSON.stringify(this.tableInfoList[0]));
      data.base_results = data.factor_results[0];
      data.factor_results.shift();
      let a = {
        id: this.$route.query.id,
        data,
      };
      save_meaconclusion(a).then((res) => {
        console.log(res);
      });
    },
		// 审核
		audit() {
			// console.log();
			let id = this.$route.query.id;
			this.$confirm("是否审核通过?", "提示", {
			  confirmButtonText: "确定",
			  cancelButtonText: "取消",
			  type: "warning",
			})
			  .then(() => {
			    inspectUp({ id }).then((res) => {
			      if (res.code == 400200) {
			        // row.inspect = 1;
							this.getData();
							this.$message({
							  type: "success",
							  message: "审核成功!",
							});
			      };
			    });
			  })
		},
		handlePrint() {
			// console.log(this.$route.query.id)
			if(!this.isPrint && !this.isInspect) {
				return this.$message.error("当前有量表未审核,不能打印");
			}
			// this.$router.push({
			// 	path:"/copydownpdf",
			// 	query: {
			// 		id:this.$route.query.id
			// 	}
			// });
			window.open(`/#/copydownpdf?id=${this.$route.query.id}`);
		}
  },
  filters: {
    waiting(val) {
      return val != 1 ? "正常" : "危险";
    },
  },
  watch: {
    chartDataList: function () {
      this.$nextTick(() => {
        // this.charData();
        this.fullscreenLoading = false;
      });
    },
  },
};
</script>
<style lang="less">
	@import "./index.less";
</style>